<template>
    <div>
        <card-header title="Trip Add-Ons" :subheader="trip.title" icon="fa-layer-plus"/>
        <card-toolbar>
            <button @click="$openCard('new-trip-addon', {tripId: props.tripId}, card)"><i class="fas fa-plus mr-2"/>New Add-On</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <task-navigation-list-item
                    title="Accommodations"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'accomodations'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'accomodations'}, card)"
                    :tasks="tasksByType('accomodations')"
                />
                <task-navigation-list-item
                    title="Extensions"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'extensions'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'extensions'}, card)"
                    :tasks="tasksByType('extensions')"
                />
                <task-navigation-list-item
                    title="Tennis"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'tennis'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'tennis'}, card)"
                    :tasks="tasksByType('tennis')"
                />
                <task-navigation-list-item
                    title="Airfare Credit"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'aircredit'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'aircredit'}, card)"
                    :tasks="tasksByType('aircredit')"
                />
                <task-navigation-list-item
                    title="Airfare"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'airfare'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'airfare'}, card)"
                    :tasks="tasksByType('airfare')"
                />
                <task-navigation-list-item
                    title="Departures"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'departure'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'departure'}, card)"
                    :tasks="tasksByType('departure')"
                />
                <task-navigation-list-item
                    title="Other"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons-list' && child.props.type === 'other'"
                    @click="$openCard('addons-list', {tripId:  props.tripId, type: 'other'}, card)"
                    :tasks="tasksByType('other')"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardList from "@/TIER/components/CardList";
    import CardToolbar from "@/TIER/components/CardToolbar";
    import SearchListItem from "@/TIER/components/SearchListItem";
    import SubheaderListItem from "@/TIER/components/SubheaderListItem";
    import SeparatorListItem from "@/TIER/components/SeparatorListItem";
    import NavigationListItem from "@/TIER/components/NavigationListItem";

    import {client as http} from '@/http_client';
    import CardBody from "@/TIER/components/CardBody";
    import TripAddOnListItem from "@/components/TripAddOnListItem";
    import TaskNavigationListItem from "@/components/TaskNavigationListItem";

    export default {
        name: "TripAddOns",
        props: ['card', 'child', 'props'],
        components: {TaskNavigationListItem, TripAddOnListItem, CardBody, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addons: [],
                trip: {}
            };
        },
        methods: {
            loadAddOns: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/addons/0/500', {force}).then(response => {
                    this.addons = response.data.addons;
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            addOnByType: function(type) {
                let arr = [];
                for (let i = 0; i < this.addons.length; i++) {
                    if (this.addons[i].type === type) arr.push(this.addons[i]);
                }
                return arr.sort(function(a, b) {
                    let textA = a.title.toUpperCase();
                    let textB = b.title.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
            },
            tasksByType: function(type) {
                let count = 0;
                for (let i = 0; i < this.addons.length; i++) {
                    if (this.addons[i].type === type && this.addons[i].task_complete === 0) count++;
                }
                return count;
            },
            reload: function() {
                this.loadAddOns(true);
            }
        },
        watch: {
            'props': function() {
                this.loadAddOns();
            }
        },
        mounted() {
            this.loadAddOns();
            this.loadTrip();
        }
    };
</script>
